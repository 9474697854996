<template>
<div>
<!--  TODO help page-->
</div>
</template>

<script>
export default {
  name: 'HelpPage'
}
</script>

<style scoped>

</style>
