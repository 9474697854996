<template>
  <!-- TODO OrderProducts template -->
  <div class="container has_table">
    <div class="table_body adds_product_table" style="padding-top: 20px">
      <div class="top_table flx">
        <h3>Список товаров</h3>
        <div class="info_sum flx">
          <div class="item flx">
            <span class="grey">Сумма дроп:  </span>
            <span>{{ drop_total }} грн</span>
            <span>
              <span class="grey" style="padding-left: 10px" v-if="discount">Со скидкой:  </span>
              <span>{{ discount }} грн</span>
            </span>
          </div>
          <div class="item flx" v-if="order.pay_type !== 1">
            <span>Укажите сумму продажи в чеке:<span class="red">*</span></span>
            <div class="inp_wrap">
              <input type="text" v-model.number="check_total" :class="{required: hasError('total_check')}">
            </div>
          </div>
          <div class="item flx" v-else>
            <span>Укажите цену продажи:<span class="red">*</span></span>
            <div class="inp_wrap">
              <input type="text" v-model.number="final_total" :class="{required: hasError('total_final')}">
            </div>
          </div>
        </div>
      </div>
      <div class="wrap_tbl">
        <div class="headline_table flx">
          <div class="img_prod">
            <p>Фото</p>
          </div>
          <div class="name_prod">
            <p>Название</p>
          </div>
          <div class="category_prod">
            <p>Категория</p>
          </div>
          <div class="size_prod">
            <p>Размер</p>
          </div>
          <div class="characteristic_prod">
            <p>Характеристики</p>
          </div>
          <div class="discount_prod">
            <p>Скидка</p>
          </div>
          <div class="quantity_prod">
            <p>Кол-во</p>
          </div>
          <div class="sum_drop_prod">
            <p>Сумма (дроп) со скидкой</p>
          </div>
          <div class="cross"></div>
        </div>
        <div class="row_list">
          <div class="row flx" v-for="item in order_products" :key="'order_product-'+item.product_color_size_id">
            <div class="img_prod">
              <img style="object-fit: cover" :src="item.image_url" :alt="item.name" :title="item.name"
                   v-if="item.image_url">
              <img v-else style="object-fit:cover" src="/images/no-img.svg" alt="">
            </div>
            <div class="name_prod">
              <p>{{ item.name }}</p>
            </div>
            <div class="category_prod">
              <p class="grey">{{ item.category_name }}</p>
            </div>
            <div class="size_prod">
              {{ item.size }}
            </div>
            <div class="characteristic_prod">
              <div class="item flx">
                <span>Артикул:</span>
                <span class="line"> </span>
                <p>{{ item.vendor_code }}</p>
              </div>
              <div class="item flx">
                <span>Размер:</span>
                <span class="line"> </span>
                <p>{{ item.size }}</p>
              </div>
              <div class="item flx">
                <span>Цвет:</span>
                <span class="line"> </span>
                <p>{{ item.color }}</p>
              </div>
              <div class="item flx">
                <span>Вес:</span>
                <span class="line"> </span>
                <p>{{ item.weight }}</p>
              </div>
            </div>
            <div class="discount_prod">
              <span v-if="item.discount">{{ item.discount.value }}{{ item.discount.type }}</span>
              <span v-else>0%</span>
            </div>
            <div class="quantity_prod">
              <input type="number" :value="item.qty" min="1" :max="item.remains"
                     @input="setOrderProductQty($event, item)">
            </div>
            <div class="sum_drop_prod">
              <p v-if="item.discount">{{ item.retail_price * item.qty - item.discount.total * item.qty | asPrice }}</p>
              <p v-else>{{ item.retail_price * item.qty | asPrice }}</p>
            </div>
            <div class="cross close" @click="deleteItem(item.id)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { colors } from '../../../../mixins/colors'
import { prices } from '../../../../mixins/prices'
import { eventBus } from '../../../../main'

export default {
  props: ['order'],
  name: 'EditOrderProduct',
  mixins: [prices, colors],
  data () {
    return {
      order_products: [],
      final_total: 0,
      check_total: 0,
      drop_total: 0,
      discount: 0
    }
  },
  watch: {
    order (v) {
      this.order_products = v.products
      this.calcDropTotal()
      this.final_total = v.total_final
      this.check_total = v.total_check
    },
    'order.products' (v) {
      this.order_products = v
      this.calcDropTotal()
    },
    final_total (v) {
      eventBus.$emit('order-total:changed', this.final_total, this.drop_total)
    },
    check_total (v) {
      eventBus.$emit('order-check-total:changed', this.check_total)
    },
    drop_total (v) {
      eventBus.$emit('order-total:changed', this.final_total, this.drop_total)
    }
  },
  computed: {
    ...mapState('cart', ['carts', 'total', 'cart_counts', 'total_final']),
    ...mapGetters('cart', ['getTotal']),
    ...mapGetters('errors', ['hasErrors', 'hasError'])
  },
  methods: {
    ...mapMutations('cart', ['setTotalFinal', 'setCartItems']),
    ...mapActions('cart', ['deleteCart', 'addItemToCart']),
    setOrderProductQty (e, product) {
      product.qty = e.target.valueAsNumber
      const index = this.order_products.findIndex(p => p.product_color_size_id === product.product_color_size_id)
      this.$set(this.order_products, index, product)
      this.calcDropTotal()
      eventBus.$emit('order-products:changed', this.order_products)
    },
    calcDropTotal () {
      this.drop_total = this.order_products.reduce((total, product) => {
        if (product.discount) {
          return total + parseFloat(product.retail_price) * parseInt(product.qty) - product.discount.total * parseInt(product.qty)
        } else {
          return total + parseFloat(product.retail_price) * parseInt(product.qty)
        }
      }, 0)

      if (this.order.discount) {
        if (this.order.discount.type === '%') {
          const dis = this.drop_total * (this.order.discount.value / 100)
          this.discount = (parseFloat(this.drop_total) - dis).toFixed(2)
        }
        if (this.order.discount.type === 'грн') {
          this.discount = (parseFloat(this.drop_total) - this.order.discount.value).toFixed(2)
        }
      }
    },
    deleteItem (id) {
      const index = this.order_products.findIndex(p => p.id === id)
      this.$delete(this.order_products, index)
    }
  }
}
</script>

<style scoped>

</style>
