<template>
  <div class="icon_wrap" @click="show = !show">
    <svg class="open_drop" data-id="comment-drop" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.33075 0.0129395C0.59684 0.0129395 0 0.60978 0 1.34369V9.32818C0 10.0621 0.59684 10.6589 1.33075 10.6589H3.71678L5.56749 12.5096C5.90231 12.8002 6.09208 12.8092 6.40925 12.5096L8.25995 10.6589H10.646C11.3799 10.6589 11.9767 10.0621 11.9767 9.32818V1.34369C11.9767 0.60978 11.3799 0.0129395 10.646 0.0129395H1.33075ZM10.646 9.32818H7.70902L5.98837 11.0488L4.26771 9.32818H1.33075V1.34369H10.646V9.32818Z" fill="#ADB8C6"/>
      <path d="M2.6615 3.33981H9.31524V4.67056H2.6615V3.33981ZM2.6615 6.00131H7.31911V7.33205H2.6615V6.00131Z" fill="#ADB8C6"/>
    </svg>
    <div v-show="show" class="menu_drop" style="display: block">
      <div class="dropdown flx">
        <span class="comment">Комментарий менеджера:</span>
        <span>{{ comment }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropDownComment',
  props: {
    comment: String
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    search () {
      console.log('test')
    }
  }
}
</script>

<style scoped>

</style>
