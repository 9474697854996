<template>
  <div style="display: block" class="popup_box" data-id="report-info">
    <div class="popup report_info">
      <div class="top flx">
        <span class="page_title">Отчёт о выплате: {{group}}</span>
<!--        <div class="btn_wrap flx">-->
<!--          <div class="btn">-->
<!--            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M5.95898 0.999959L5.95898 10.9166" stroke="white" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              <path d="M10.9167 13H1" stroke="white" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              <path d="M1.00081 5.95829L5.95915 10.9166L10.9175 5.95829" stroke="white" stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            </svg>-->
<!--            <span>Скачать отчёт</span>-->
<!--          </div>-->
<!--          <div class="btn empty">Печать отчёта</div>-->
<!--        </div>-->
      </div>
      <span class="close_popup" @click="close()"></span>
      <div class="table_body payments_general">
        <div class="headline_table flx">
          <div class="id_ttn">
            <p class="title">ID・ТТН</p>
          </div>
          <div class="client_info">
            <p>Клиент</p>
          </div>
          <div class="date_change_info">
            <p>ДАТА・Изм</p>
          </div>
          <div class="status_info">
            <p>СТАТУС</p>
          </div>
          <div class="drop_price_info">
            <p>Дроп・сумма</p>
          </div>
          <div class="calculations_info">
            <p>Ваша прибыль</p>
          </div>
        </div>
        <div class="row_list">
          <div class="row flx" v-for="invoice in invoices" :key="invoice.id">
            <div class="id_ttn">
              <span class="orange"><b>{{ invoice.id }}</b></span>
              <span><b>{{ invoice.order.ttn }}</b></span>
            </div>
            <div class="client_info">
              <span>{{ invoice.order.client_data.first_name }} {{ invoice.order.client_data.last_name }}</span>
            </div>
            <div class="date_change_info">
              <span>{{ invoice.created_at }}</span>
              <span class="grey">{{ invoice.updated_at }}</span>
            </div>
            <div class="status_info">
              <span class="status " :class="getOrderStatusColor(invoice.order)">{{
                  invoice.order.status_name
                }}</span>
            </div>
            <div class="drop_price_info">
              <span>{{ invoice.order.total_discount }} грн</span>
              <span class="grey">{{ invoice.order.total_final }} грн</span>
            </div>
            <div class="calculations_info">
              <span class="green"><b>{{ invoice.amount * -1 }} грн </b></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import helper from '../../../helpers/helper'
import axios from 'axios'

export default {
  name: 'PaymentReportDetails',
  props: {
    group: Number
  },
  data () {
    return {
      invoices: []
    }
  },
  watch: {
    group () {
      this.getInvoices()
    }
  },
  methods: {
    getInvoices () {
      axios.get('/api/invoices/by-group/' + this.group)
        .then(res => {
          this.invoices = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    getOrderStatusColor (order) {
      return helper.getOrderStatusColor(order.status)
    },
    close () {
      this.$root.$emit('close_report')
    }
  },
  created () {
    this.getInvoices()
  }
}
</script>

<style scoped>

</style>
