<template>
  <div class="container" v-if="order_data">
    <div class="order_info" style="position: relative;z-index: 1">
      <div class="top flx">
        <h3>Доставка и оплата</h3>
        <div class="right_box flx">
          <div class="pay_wrap flx">
            <div class="item" v-if="order.discount">
              <span class="pay_title">Скидка на заказ:</span>
              <span class="pay_amount green">{{order.discount.value }}<span>{{ order.discount.type }}</span></span>
            </div>
            <div class="item" style="padding-left: 20px">
              <span class="pay_title">Выплата продавцу:</span>
              <span class="pay_amount" :class="profit >= 0 ? 'green' : 'red'">{{profit }} <span>грн</span></span>
            </div>
            <div class="item">
              <span class="pay_title">К оплате:</span>
              <span class="pay_amount">{{ total_final.toFixed(2) }} <span>грн</span></span>
            </div>
          </div>
          <button class="btn open_pop" data-id="new-order" :class="{ disabled: disabled }" :disabled="disabled"
                  @click="save">Сохранить
          </button>
        </div>
      </div>
      <div class="input_wrap_outer flx">
        <div class="inp_wrap">
          <p>Фамилия<span class="red">*</span></p>
          <input type="text" name="last_name" v-model="order_data.client_data.last_name"
                 :class="{ required: hasError('client_data.last_name') }">
        </div>
        <div class="inp_wrap">
          <p>Имя <span class="red">*</span></p>
          <input type="text" name="first_name" v-model="order_data.client_data.first_name"
                 :class="{ required: hasError('client_data.first_name') }">
        </div>
        <div class="inp_wrap">
          <p>Отчество</p>
          <input type="text" name="middle_name" v-model="order_data.client_data.middle_name">
        </div>
        <div class="inp_wrap">
          <p>Телефон<span class="red">*</span></p>
          <input type="tel" name="phone" v-model="order_data.client_data.phone" v-mask="phone_mask"
                 :class="{ required: hasError('client_data.phone') }">
        </div>
        <div class="inp_wrap">
          <p>Вариант доставки<span class="red">*</span></p>
          <div class="select">
            <i class="ti-angle-down"></i>
            <brew-select
              class="select-delivery vue-select"
              :clearable="false"
              :options="deliveries"
              label="name"
              :reduce="item => item.id"
              v-model="order_data.delivery_data.delivery_id"
            ></brew-select>
          </div>
        </div>
        <div class="inp_wrap">
          <p>Кто оплачивает<span class="red">*</span></p>
          <div class="select disabled" id="storage" v-if="config && config.order">
            <brew-select
              :clearable="false"
              class="select-delivery-pay-person vue-select"
              :options="config.order.pay_person"
              label="name"
              :reduce="item => item.id"
              v-model="order_data.delivery_data.delivery_pay_person"
            ></brew-select>
          </div>
        </div>
        <div class="inp_wrap">
          <p>Вариант оплаты<span class="red">*</span></p>
          <div class="select">
            <i class="ti-angle-down"></i>
            <brew-select
              v-if="config && config.order"
              class="select-delivery vue-select"
              :clearable="false"
              :options="config.order.pay_types"
              label="name"
              :reduce="item => item.id"
              v-model="order_data.pay_type"
            ></brew-select>
          </div>
        </div>
        <div class="inp_wrap">
          <p>Кто оплачивает<span class="red">*</span></p>
          <div class="select">
            <i class="ti-angle-down"></i>
            <brew-select
              v-if="config && config.order"
              class="select-delivery vue-select"
              :clearable="false"
              :options="filtered_pay_person"
              label="name"
              :reduce="item => item.id"
              v-model="order_data.pay_person"
              :selectable="option => ! option.disabled"
            ></brew-select>
          </div>
        </div>
        <div class="inp_wrap">
          <p>Доставка <span class="red">*</span></p>
          <div class="select">
            <i class="ti-angle-down"></i>
            <brew-select
              class="select-delivery vue-select"
              :clearable="false"
              :options="delivery_methods"
              label="name"
              :reduce="item => item.id"
              v-model="order_data.delivery_method_id"
            ></brew-select>
          </div>
        </div>
        <div class="inp_wrap">
          <p>Город<span class="red">*</span></p>
          <auto-select
            :class="{ required: hasError('client_data.city_id') }"
            :default-id="order_data.client_data.city_id"
            :default-name="order_data.client_data.is_ua ? (order_data.client_data.city ? order_data.client_data.city.name_ua : null) : (order_data.client_data.city ? order_data.client_data.city.name : null)"
            :items="cities"
            @search="fetchCities"
            @select="selectCity"
            @active-input="fetchCities"
            @clear-select="clearCity"
          >
          </auto-select>
        </div>
        <div class="inp_wrap" v-if="order_data.delivery_method_id === 1">
          <p>Отделение НП<span class="red">*</span></p>
          <auto-select
            key="branch1"
            :class="{ required: hasError('client_data.branch_id') }"
            :default-id="order_data.client_data.branch_id"
            :default-name="order_data.client_data.is_ua ? (order_data.client_data.branch ? order_data.client_data.branch.name_ua : null) : (order_data.client_data.branch ? order_data.client_data.branch.name : null)"
            :items="branches"
            @search="fetchBranches"
            @select="selectBranch"
            @active-input="fetchBranches"
            @clear-select="clearBranch"
          ></auto-select>
        </div>
        <div class="inp_wrap" v-if="order_data.delivery_method_id === 2">
          <p>Адрес<span class="red">*</span></p>
          <auto-select
            key="branch2"
            :default-name="order_data.client_data.address.street.name"
            :class="{ required: hasError('client_data.address.street.ref') }"
            :items="addresses"
            @search="fetchAddresses"
            @select="selectAddress"
            @active-input="fetchAddresses"
          />
        </div>
        <div class="inp_wrap flx" v-if="order_data.delivery_method_id === 2">
          <div style="width: 150px">
            <p>Дом<span class="red">*</span></p>
            <input type="text" name="last_name" v-model="order_data.client_data.address.house">
          </div>
          <div style="width: 100px">
            <p>Квартира<span class="red">*</span></p>
            <input type="text" name="last_name" v-model="order_data.client_data.address.appt">
          </div>
        </div>
        <div class="inp_wrap">
          <p>Комментарий</p>
          <input type="text" v-model="order_data.seller_comment" :class="{ required: hasError('seller_comment') }">
        </div>
        <div class="inp_wrap add-file">
          <p>Прикрепить файл</p>
          <div class="flx">
          <input
              type="file"
              :class="{ required: hasError('file') }"
              accept="image/png, image/jpeg"
              ref="file"
          >
          <button
              :disabled="is_upload_process"
              :class="{disabled: is_upload_process}"
              class="btn"
              @click="storeFile()">
            <span v-if="order.file === null">Отправить файл</span>
            <span v-else>Обновить файл</span>
          </button>
          </div>
          <p style="margin-top: 10px"><a
              style="color: darkorange"
              v-if="order.file"
              target="_blank"
              :href="order.file">Посмотреть файл</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import 'vue-select/dist/vue-select.css'
import BrewSelect from '../../../../helpers/components/BrewSelect'
import AutoSelect from '../../../../helpers/components/AutoSelect'
import { eventBus } from '../../../../main'

export default {
  props: ['order'],
  name: 'EditOrderInfo',
  inject: ['user_id'],
  directives: {
    mask
  },
  components: {
    BrewSelect,
    AutoSelect
  },
  computed: {
    ...mapState('orders', ['is_upload_process']),
    ...mapGetters('cart', ['getTotal']),
    ...mapState('config', ['config']),
    ...mapState('delivery', ['cities', 'branches', 'addresses']),
    ...mapGetters('errors', ['hasErrors', 'hasError']),
    disabled () {
      return false
    },
    filtered_pay_person () {
      const payPersons = []
      if (this.config?.order?.pay_person) {
        this.config.order.pay_person.forEach(item => {
          if (this.order_data.pay_type === 1) {
            item.disabled = this.order_data.pay_type === 1 && item.id !== 1
          }
          if (this.order_data.pay_type === 2) {
            item.disabled = false
          }
          if (this.order_data.pay_type === 3) {
            item.disabled = this.order_data.pay_type === 3 && item.id !== 2
          }
          payPersons.push(item)
          return item
        })
        console.log(payPersons)
        return payPersons
      }
      return []
    }
  },
  data () {
    return {
      delivery_methods: [
        { id: 1, name: 'На отделение' }, { id: 2, name: 'Адресная доставка' }
      ],
      profit: 0,
      total_final: 0,
      total_check: 0,
      drop_total: 0,
      order_data: null,
      products: [],
      city: null,
      branch: null,
      phone_mask: '+38(0##) ###-##-##',
      client_data: {
        city_id: null,
        branch_id: null,
        first_name: null,
        last_name: null,
        middle_name: null,
        phone: null,
        delivery_id: 1,
        is_ua: false
      },
      delivery_data: {
        delivery_id: 1,
        delivery_pay_person: 1
      },
      pay_type: 1,
      pay_person: 1,
      seller_comment: null,
      deliveries: [
        { id: 1, name: 'Новая почта' }
      ]
    }
  },
  methods: {
    ...mapMutations('errors', ['clearErrors']),
    ...mapActions('cart', ['getCarts', 'clearCart']),
    ...mapActions('delivery', ['getCities', 'getBranches', 'getDeliveries']),
    ...mapActions('orders', ['updateOrder', 'getOrder', 'uploadFile']),
    ...mapMutations('delivery', ['setSearchBranch', 'setBranches']),
    prepare () {
      this.clearErrors()
      this.getOrder(this.$route.params.id)
    },
    fetchCities (search) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getCities(search)
      }, 200)
    },
    fetchBranches (search) {
      if (this.order_data.client_data.city_id) {
        this.setBranches([])
        this.order_data.client_data.branch_id = null
        this.order_data.client_data.branch = { id: null, name: null, name_ua: null }
        this.setSearchBranch(search)
        this.getBranches({
          city_id: this.order_data.client_data.city_id,
          is_ua: this.order_data.client_data.is_ua
        })
      }
    },
    fetchAddresses (search) {
      this.client_data.address.street = {}
      const data = {
        street: search,
        city_ref: this.order.client_data.city.ref
      }
      this.getAddresses(data)
    },
    selectAddress (data) {
      this.order.client_data.address.street = data
    },
    clearCity () {
      this.order_data.client_data.city_id = null
      this.order_data.client_data.city = { name: null, name_ua: null }
      this.fetchCities('')
    },
    clearBranch () {
      this.order_data.client_data.branch_id = null
      this.order_data.client_data.branch = { name: null, name_ua: null }
      this.fetchBranches('')
    },
    selectCity (data) {
      this.order_data.client_data.city_id = data.id
      this.order_data.client_data.city = { name: data.name, name_ua: data.name }
      this.order_data.client_data.is_ua = data.is_ua
      this.fetchBranches('')
    },
    selectBranch (data) {
      this.order_data.client_data.branch_id = data.id
    },
    save () {
      const orderData = {
        dropshipper_id: this.user_id(),
        delivery_method_id: this.order_data.delivery_method_id,
        client_data: this.order_data.client_data,
        delivery_data: this.order_data.delivery_data,
        pay_type: this.order_data.pay_type,
        pay_person: this.order_data.pay_person,
        seller_comment: this.order_data.seller_comment,
        products: this.products.map(item => {
          return {
            id: item.id,
            product_color_size_id: item.product_color_size_id,
            qty: item.qty,
            discount: item.discount
          }
        }),
        total_final: this.total_final,
        total_check: this.total_check ? this.total_check : 0
      }
      this.updateOrder(orderData)
        .then(() => {
          // this.$router.push({ name: 'Orders' })
        })
    },
    searchByName: (option, label, search) => {
      const temp = search.toLowerCase()
      return option.name.toLowerCase().indexOf(temp) > -1 ||
        option.name_ua.toLowerCase().indexOf(temp) > -1
    },
    storeFile () {
      // let formData = new FormData();
      // for (var i = 0; i < this.$refs.file.files.length; i++) {
      //   let file = this.$refs.file.files[i];
      //   formData.append('files[' + i + ']', file);
      // }
      // this.images = formData
      if (this.$refs.file.files.length > 0) {
        const formData = new FormData()
        const file = this.$refs.file.files[0]
        formData.append('file', file)
        this.uploadFile({
          order_id: this.order.id,
          data: formData
        })
      }
    }
  },
  watch: {
    order (v) {
      this.order_data = v
      this.products = v.products
    },
    'order_data.pay_type' (value) {
      if (value === 1) {
        this.order_data.pay_person = 1
      }
      if (value === 3) {
        this.order_data.pay_person = 2
      }
    },
    'delivery_data.delivery_id' (val) {
      this.client_data.delivery_id = val
    },
    city (value) {
      this.client_data.city_id = value.id
      this.client_data.is_ua = value.is_ua
      this.client_data.branch_id = null
    }
  },
  created () {
    this.prepare()
    eventBus.$on('order-total:changed', (final, drop) => {
      this.total_final = final
      this.drop_total = drop
      console.log('prof', (this.total_final - this.drop_total).toFixed(2))
      this.profit = (this.total_final - this.drop_total).toFixed(2)

      if (this.order.discount) {
        if (this.order.discount.type === '%') {
          const discount = this.drop_total * (this.order.discount.value / 100)
          this.profit = (parseFloat(this.profit) + discount).toFixed(2)
        }
        if (this.order.discount.type === 'грн') {
          this.profit = (parseFloat(this.profit) + this.order.discount.value).toFixed(2)
        }
      }
    })
    eventBus.$on('order-products:changed', (products) => {

    })
    eventBus.$on('order-check-total:changed', (v) => {
      this.total_check = v
    })
  }
}
</script>

<style lang="scss">
.vs__dropdown-menu {
  z-index: 10030 !important;
}
.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.disabled {
  .vs__selected {
    color: #afafaf;
  }
}
.add-file {
  width: 50% !important;
  .flx {
    justify-content: flex-start!important;
    input {
      width: 350px;
      border-top-right-radius: 0!important;
      border-bottom-right-radius: 0!important;
    }
    .btn {
      border-top-left-radius: 0!important;
      border-bottom-left-radius: 0!important;
      padding: 10px 12px!important;
    }
  }
}
</style>
