<template>
  <div class="table_body table_all_orders">
    <div class="top_table flx">
      <div class="wrap_search flx">
        <button type="submit"></button>
        <input type="text" placeholder="Поиск...">
        <ul class="search_list_box">
          <li class="item"><a href="#">item</a></li>
          <li class="item"><a href="#">item</a></li>
          <li class="item"><a href="#">item</a></li>
          <li class="item"><a href="#">item</a></li>
          <li class="item"><a href="#">item</a></li>
        </ul>
      </div>
      <div class="pagination flx">
        <div class="flx">
          <p>Строк:  &nbsp;</p>
            <brew-select
              style="width: 100px"
              class="select-pagination"
              :clearable="false"
              v-model="perPage"
              :options="page_sizes"
            >
            </brew-select>
        </div>
        <paginate
          v-model="current_page"
          style="margin-left: 20px"
          class="flx"
          :page-count="opened_pagination.last_page"
          :page-range="3"
          :margin-pages="2"
          :prev-text="'<'"
          :next-text="'>'"
        >
        </paginate>
      </div>
    </div>
    <div class="bot_table">
      <div class="headline_table flx">
        <div class="id_ttn">
          <p class="title">ID・ТТН</p>
        </div>
        <div class="client_info">
          <p>Клиент</p>
        </div>
        <div class="date_change_info">
          <p>ДАТА・Изм</p>
        </div>
        <div class="status_info">
          <p>СТАТУС</p>
        </div>
        <div class="drop_price_info">
          <p>Дроп・сумма</p>
        </div>
        <div class="delivery_status_info">
          <p>Состояние доставки</p>
        </div>
        <div class="calculations_info">
          <p>Ваша прибыль</p>
        </div>
        <div class="documents_info">
          <p>Документы</p>
        </div>
        <div class="icon_wrap"></div>
      </div>
      <div class="row_list">
        <div class="row flx" v-for="invoice in opened_invoices" :key="invoice.id">
          <div class="id_ttn"><span class="orange"><b>{{ invoice.id }}</b>
                  <svg class="open_pop" data-id="list_product" width="14" height="12" viewBox="0 0 14 12" fill="none"
                       xmlns="http://www.w3.org/2000/svg"><path
                    d="M4.24443 3.93334C3.86397 3.93334 3.55554 4.24176 3.55554 4.62222C3.55554 5.00269 3.86397 5.31111 4.24443 5.31111H9.75554C10.136 5.31111 10.4444 5.00269 10.4444 4.62222C10.4444 4.24176 10.136 3.93334 9.75554 3.93334H4.24443Z"
                    fill="#ADB8C6"/><path
                    d="M4.24443 6.68889C3.86397 6.68889 3.55554 6.99732 3.55554 7.37778C3.55554 7.75824 3.86397 8.06667 4.24443 8.06667H7.68888C8.06934 8.06667 8.37777 7.75824 8.37777 7.37778C8.37777 6.99732 8.06934 6.68889 7.68888 6.68889H4.24443Z"
                    fill="#ADB8C6"/><path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M0.799988 1.86667C0.799988 1.10682 1.41792 0.488892 2.17777 0.488892H11.8222C12.5821 0.488892 13.2 1.10682 13.2 1.86667V10.1333C13.2 10.8932 12.5821 11.5111 11.8222 11.5111H2.17777C1.41792 11.5111 0.799988 10.8932 0.799988 10.1333V1.86667ZM10.8669 10.1333H3.13306H2.17777V9.17804V2.82196V1.86667H3.13306H10.8669H11.8222V2.82196V9.17805V10.1333H10.8669Z"
                                          fill="#ADB8C6"/></svg></span>
            <span><b>{{ invoice.ttn }}</b></span>
          </div>
          <div class="client_info">
            <span>{{ invoice.order.client_data.first_name }} {{ invoice.order.client_data.last_name }}</span>
          </div>
          <div class="date_change_info">
            <span>{{ invoice.created_at }}</span>
            <span class="grey">{{ invoice.updated_at }}</span>
          </div>
          <div class="status_info">
                  <span class="status" :class="getOrderStatusColor(invoice.order)">{{
                      invoice.order.status_name
                    }}</span>
          </div>
          <div class="drop_price_info">
            <span>{{ invoice.order.total_discount }} грн</span>
            <span class="grey">{{ invoice.order.total_final }} грн</span>
          </div>
          <div class="delivery_status_info">
            <span class="status blue">{{ invoice.order.delivery_status_name }}</span>
          </div>
          <div class="calculations_info">
            <span class="green"><b>{{ invoice.amount * -1 }} грн </b></span>
          </div>
          <div class="documents_info">
            <span>[{{ invoice.comment }}]</span>
          </div>
          <div class="icon_wrap"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from '../../../helpers/helper'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'PendingOrders',
  props: {
    opened_invoices: Array,
    opened_pagination: Object
  },
  data () {
    return {
      page: 1,
      page_sizes: [10, 20, 30, 50]
    }
  },
  computed: {
    ...mapState('invoices', ['opened_invoices', 'invoice_user', 'opened_pagination', 'opened_filters']),
    perPage: {
      get () {
        return this.opened_filters.per_page
      },
      set (val) {
        this.setOpenedPerPage(val)
        this.$nextTick(() => {
          this.setOpenedPage(1)
          this.getOpenedInvoices()
        })
      }
    },
    current_page: {
      get () {
        return this.opened_filters.page
      },
      set (value) {
        this.setOpenedPage(value)
        this.getOpenedInvoices()
      }
    }
  },
  methods: {
    ...mapActions('invoices', ['getOpenedInvoices']),
    ...mapMutations('invoices', ['setOpenedPerPage', 'setOpenedPage']),
    getOrderStatusColor (order) {
      return helper.getOrderStatusColor(order.status)
    }
  }
}
</script>

<style scoped>

</style>
