<template>
  <div>
    <section class="new_order" v-if="order">
      <edit-order-info :order="order"></edit-order-info>
      <edit-order-product :order="order"></edit-order-product>
      <div class="container has_table">
        <add-order-item :order="order"></add-order-item>
      </div>
    </section>
  </div>
</template>

<script>
import EditOrderInfo from './EditOrder/EditOrderInfo'
import EditOrderProduct from './EditOrder/EditOrderProduct'
import AddOrderItem from './EditOrder/AddOrderItem'
import { mapActions, mapState } from 'vuex'
import { eventBus } from '../../../main'

export default {
  name: 'EditOrder',
  components: {
    EditOrderInfo,
    EditOrderProduct,
    AddOrderItem
  },
  computed: {
    ...mapState('orders', ['order'])
  },
  methods: {
    ...mapActions('orders', ['getOrder'])
  },
  created () {
    this.getOrder(this.$route.params.id)
    eventBus.$on('order-products:added', (product) => {
      const products = this.order.products
      products.push(product)
      this.order.products = products
    })
  }
}
</script>

<style scoped>

</style>
