<template>
  <div>
    <cabinet-header></cabinet-header>
    <section>
      <cabinet-menu></cabinet-menu>
      <router-view :key="$router.path" />
    </section>
  </div>
</template>

<script>
import CabinetHeader from '../../components/modules/Cabinet/CabinetHeader'
import CabinetMenu from '../../components/modules/Cabinet/CabinetMenu'
export default {
  name: 'CabinetPage',
  components: {
    CabinetHeader,
    CabinetMenu
  },
  metaInfo () {
    return {
      title: 'Личный кабинет'
    }
  },
  pageClass: 'cabinet-page'
}
</script>

<style scoped>

</style>
