<template>
  <div class="main_info flx">
    <div class="statistic_outer flx" id="chart">
      <div>
        <p @click="selectPeriod('day')" class="btn-p"  :class="{'active': ordersConf.period === 'day'}">День</p>
        <p @click="selectPeriod('week')" class="btn-p"  :class="{'active': ordersConf.period === 'week'}">Неделя</p>
        <p @click="selectPeriod('month')" class="btn-p"  :class="{'active': ordersConf.period === 'month'}">Месяц</p>
        <p @click="selectPeriod('year')" class="btn-p"  :class="{'active': ordersConf.period === 'year'}">Год</p>
        <p @click="selectPeriod('all_time')" class="btn-p"  :class="{'active': ordersConf.period === 'all_time'}">Все время</p>
      </div>
      <apexchart type="radialBar" height="350" :options="chartOptions" :series="series"></apexchart>
      <div>
          <p><span class="statistic-circle green"></span><span class="count">{{ordersConf.counter.completed_count}}</span> Завершенных</p>
          <p><span class="statistic-circle blue"></span><span class="count">{{ordersConf.counter.send_count}}</span> Отправленных</p>
          <p><span class="statistic-circle red"></span><span class="count">{{ordersConf.counter.return_count}}</span> Возвратов</p>
      </div>
    </div>
    <div class="right_box">
      <div class="pers_info_inner flx">
        <img src="images/user.png" alt="user_photo">
        <div class="personal_info">
          <div class="name_wrap flx">
            <div class="flx">
              <span class="name">{{ user ? user.name : '' }}</span>
              <span class="grey">{{ user ? user.email : '' }}</span>
            </div>
<!--            <span class="status blue">Новичок</span>-->
          </div>
<!--          <div class="progress_bar_container flx">-->
<!--            <div class="progress_bar_wrap">-->
<!--              <div class="progress_line" style="width: 60%;"></div>-->
<!--            </div>-->
<!--            <div class="level_wrap">-->
<!--              <span class="grey">Сделайте еще 35 заказов в этом месяце</span>-->
<!--              <span class="grey">И получите уровень<span class="level">PRO</span> <span class="info_green_ico"></span></span>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'ProfileInfo',
  components: { apexchart: VueApexCharts },
  data () {
    return {
      ordersConf: {
        period: 'day',
        counter: {
          total_count: 0,
          completed_count: 0,
          send_count: 0,
          return_count: 0
        }
      },
      series: [0, 0, 0],

      chartOptions: {
        chart: {
          height: 350,
          type: 'radialBar'
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '22px'
              },
              value: {
                fontSize: '16px'
              },
              total: {
                show: true,
                label: 'Заказов',
                value: 1,
                formatter: () => {
                  return this.getTotal()
                }
              }
            }
          }
        },
        colors: ['#0fa75e', '#005aba', '#fd5e75'],
        labels: ['Завершенных', 'Отправленных', 'Возвратов']
      }
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    getTotal () {
      return this.ordersConf.counter.total_count
    },
    getStatistics () {
      axios.get('api/users-statistic?period=' + this.ordersConf.period).then(res => {
        console.log(res.data)
        this.$set(this.series, 0, res.data.perc.completed_perc) // Завершенныые
        this.$set(this.series, 1, res.data.perc.send_perc) // Отправленные
        this.$set(this.series, 2, res.data.perc.return_perc) // возврат
        this.ordersConf.counter.completed_count = res.data.completed_count
        this.ordersConf.counter.send_count = res.data.send_count
        this.ordersConf.counter.return_count = res.data.return_count
        this.ordersConf.counter.total_count = res.data.total_count
      })
    },
    selectPeriod (period) {
      this.ordersConf.period = period
      this.getStatistics()
    }
  },
  created () {
    this.getStatistics()
  }
}
</script>

<style scoped>
.progress_bar_container{
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.payments_page_top .progress_bar_container{
  padding-left: 40px;
  border-left: 1px solid #E0E5EB;
}
.progress_bar_wrap{
  width: 300px;
  height: 10px;
  margin-bottom: 6px;
  border-radius: 6px;
  background: #EDF2F9;
}
.progress_line{
  height: 100%;
  background: #F5A32A;
  border-radius: 6px;
}
.progress_bar_container .grey{
  display: block;
}
.progress_bar_container .level{
  color: #F5A32A;
  font-family: 'Gilroy Semibold', sans-serif;
  padding-left: 4px;
}
</style>
