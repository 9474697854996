<template>
<!--  TODO OrderStatusBbtn template-->
  <div class="left_box wrap_orders_status">
    <span class="item orange" :class="{ active: !active_status}" @click="setActive(null)">Все: <span>{{ all_count }}</span></span>
    <span class="item blue" :class="{ active: active_status === 2 }" @click="setActive(2)">Отправленых: <span>{{ send_count }}</span></span>
    <span class="item green" :class="{ active: active_status === 3 }" @click="setActive(3)">Завершенные: <span>{{ done_count }}</span></span>
    <span class="item red" :class="{ active: active_status === 4 }" @click="setActive(4)">Возвратов: <span>{{ return_count }}</span></span>
  </div>
</template>

<script>
export default {
  name: 'OrderStatusButtons',
  props: {
    all_count: {
      type: Number,
      default: 0
    },
    done_count: {
      type: Number,
      default: 0
    },
    send_count: {
      type: Number,
      default: 0
    },
    return_count: {
      type: Number,
      default: 0
    },
    active_status: {
      type: Number,
      default: null
    }
  },
  methods: {
    setActive (status) {
      this.$emit('update:active_status', status)
      this.$nextTick(() => {
        this.$emit('update')
      })
    }
  }
}
</script>

<style scoped>

</style>
