<template>
  <div class="container">
    <div class="info_seller">
      <div class="input_wrap_outer flx">
        <input style="display: none" type="email" name="email" />
        <input style="display: none" type="text" name="login" />
        <input style="display: none" type="password" name="password" />
        <div class="inp_wrap">
          <p>ФИО<span>*</span></p>
          <input v-model="user_data.name" type="text" :class="{required:hasError('name')}">
        </div>
        <div class="inp_wrap">
          <p>Логин<span>*</span></p>
          <input type="text"  v-model="user_data.login" :class="{required:hasError('login')}">
        </div>
        <div class="inp_wrap">
          <p>Телефон</p>
          <input type="tel" :class="{required:hasError('phone')}" v-model="user_data.phone" v-mask="phone_mask">
        </div>
        <div class="inp_wrap">
          <p>E-mail</p>
          <input type="text" v-model="user_data.email" :class="{required:hasError('email')}">
        </div>
        <div class="inp_wrap">
          <p>Telegram</p>
          <input type="text" v-model="user_data.telegram" :class="{required:hasError('telegram')}">
        </div>
        <div class="inp_wrap">
          <p>Карта для выплат</p>
          <input style="display: none" type="email" name="email" />
          <input style="display: none" type="text" name="login" />
          <input style="display: none" type="password" name="password" />
          <input
            type="text"
            name="card"
            placeholder="4444 4444 4444 4444"
            v-model="user_data.card"
            autocomplete="false"
            v-mask="'####-####-####-####'"
            :class="{required:hasError('card')}"
          >
        </div>
        <div class="inp_wrap">
          <p>Номер расчетного счета</p>
          <input
              type="text"
              placeholder=""
              v-model="user_data.score"
              autocomplete="false"
              :class="{required:hasError('score')}"
          >
        </div>
      </div>
      <div class="row flx">
        <div class="image_wrap flx">
        </div>
        <button class="btn" @click="updateUserData(user_data)">Сохранить</button>
      </div>
      <div class="change_pass">
        <span class="page_title">Смена пароля</span>
        <div class="input_wrap_outer flx">
          <div class="inp_wrap">
            <p>Пароль:</p>
            <input type="password" v-model="password_data.password" autocomplete="new-password">
<!--            <span class="show_pass">Показать</span>-->
          </div>
          <div class="inp_wrap">
            <p>Новый пароль:</p>
            <input type="password" v-model="password_data.new_password">
<!--            <span class="show_pass">Показать</span>-->
          </div>
          <div class="inp_wrap">
            <p>Повторите новый пароль:</p>
            <input type="password" v-model="password_data.confirm_new_password">
<!--            <span class="show_pass">Показать</span>-->
          </div>
        </div>
        <button class="btn">Изменить</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mask } from 'vue-the-mask'

export default {
  name: 'PersonalDataPage',
  directives: {
    mask
  },
  data () {
    return {
      phone_mask: '+38(0##) ###-##-##',
      user_data: {
        name: '',
        login: '',
        email: '',
        telegram: '',
        phone: '',
        card: '',
        score: ''
      },
      password_data: {
        password: '',
        new_password: '',
        confirm_new_password: ''
      }
    }
  },
  metaInfo () {
    return {
      title: 'Личные данные - Личный кабинет'
    }
  },
  pageClass: 'cabinet-page personal_data-page',
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('errors', ['hasErrors', 'hasError'])
  },
  methods: {
    ...mapActions('auth', ['updateUserData'])
  },
  watch: {
    user () {
      this.user_data.email = this.user.email
      this.user_data.name = this.user.name
      this.user_data.login = this.user.login
      this.user_data.phone = this.user.phone
      this.user_data.telegram = this.user.telegram
      this.user_data.card = this.user.card
      this.user_data.score = this.user.score
    }
  },
  created () {
    this.user_data.email = this.user.email
    this.user_data.name = this.user.name
    this.user_data.login = this.user.login
    this.user_data.telegram = this.user.telegram
    this.user_data.phone = this.user.phone
    this.user_data.card = this.user.card
  }
}
</script>

<style scoped>

</style>
