<template>
  <!-- TODO ProductsInOrderModal template -->
  <div class="popup_box" data-id="list_product" :class="{ active: show }" @click.self="close">
    <div class="popup list_prod">
      <span class="page_title">Товары в заказе: {{ order.id }}</span>
      <span class="close_popup" @click="close"></span>
      <div class="table_body">
        <div class="headline_table flx">
          <div class="code_prod">
            <p>Артикул</p>
          </div>
          <div class="name_prod">
            <p>Название</p>
          </div>
          <div class="color_prod">
            <p>Цвет</p>
          </div>
          <div class="size_prod">
            <p>Размер</p>
          </div>
          <div class="quantity_prod">
            <p>Кол-во</p>
          </div>
          <div class="price_drop_prod">
            <p>Цена дроп</p>
          </div>
        </div>
        <div class="row_list">
          <div class="row flx" v-for="product in order.products" :key="'product-in-order'+product.product_color_size_id">
            <div class="code_prod">
              <p>{{ product.vendor_code }}</p>
            </div>
            <div class="name_prod">
              <p>{{ product.name}}</p>
            </div>
            <div class="color_prod">
              <p>{{ product.color }}</p>
            </div>
            <div class="size_prod">
              <p>{{ product.size }}</p>
            </div>
            <div class="quantity_prod">
              <p>{{ product.qty }}</p>
            </div>
            <div class="price_drop_prod">
              <p>{{ product.total_final }} грн</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductsInOrderModal',
  props: {
    order: {
      type: Object,
      default: () => {
        return {
          id: null,
          products: []
        }
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style scoped>

</style>
