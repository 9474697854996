<template>
  <div>
    <section class="payments_page_top">
      <div class="container">
        <h2>Файл выгрузки товара YML/XML</h2>
        <p>Выгрузка подходит для всех площадок которые поддерживают импорт YML, например Prom.ua</p>
        <p>Файл обновляется каждый час и его можно использовать для обновления остатка товара</p>
        <p style="margin-top: 20px">
          <a target="_blank" class="btn" href="https://back.brewdrop.in.ua/storage/prom.xml">
            Ссылка на выгрузку товара в формате YML
          </a>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HelpPage'
}
</script>

<style scoped>

</style>
