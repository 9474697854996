<template>
  <div class="container has_table">
    <div class="table_head flx">
      <order-status-buttons
        :all_count="counters.all"
        :send_count="counters.send"
        :done_count="counters.completed"
        :return_count="counters.returned"
        :active_status.sync="order_status"
        @update="getOrders"
      ></order-status-buttons>
      <div class="right_box flx">
        <div class="wrap_action active flx">
<!--          <div class="flx">-->
<!--            <span class="quantity" v-if="selected">Отмечено: {{ selected.length }}</span>-->
<!--            <span class="reset_wrap cursor-pointer"  v-if="selected && selected.length > 0" @click="setSelectedOrders([])"><span class="close"> </span><span class="red desctop_v">Сбросить</span></span>-->
<!--          </div>-->
<!--          <div class="btn empty">Экспорт</div>-->
        </div>
<!--        <span class="point desctop_v"></span>-->
        <div class="btn actions_btn empty"  data-id="filters_box" :class="{ active: show_filters }" @click="toggleShowFilters">
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3333 1H1L6.33333 7.30667V11.6667L9 13V7.30667L14.3333 1Z" stroke="#F5A32A" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Фильтры</span>
          <span class="counter_filters" v-if="count_order_filters() > 0">{{ count_order_filters() }}</span>
        </div>
      </div>
      <orders-filter
        :config="order_config"
        :senders="senders"
        :show_filters="show_filters"
        @clear="clearFilters"
        @update="getOrders"
        :order_id.sync="orderId"
        :sender_id.sync="senderId"
        :pay_type.sync="payType"
        :status.sync="order_status"
        :delivery_status.sync="deliveryStatusId"
        :client_phone.sync="clientPhone"
        :client_last_name.sync="clientLastName"
        :city_id.sync="cityId"
        :category_id.sync="categoryId"
        :change_from.sync="changeFrom"
        :change_to.sync="changeTo"
        :meta="meta"
      ></orders-filter>
    </div>
    <div class="table_body table_all_orders">
      <div class="top_table flx">
        <div class="wrap_search flx">
          <button type="submit"></button>
          <input type="text" placeholder="Поиск..." v-model="search_order" id="search_table">
          <ul class="search_list_box">
            <li class="item"><a href="#">item</a></li>
            <li class="item"><a href="#">item</a></li>
            <li class="item"><a href="#">item</a></li>
            <li class="item"><a href="#">item</a></li>
            <li class="item"><a href="#">item</a></li>
          </ul>
        </div>
        <div class="pagination flx">
          <div class="flx">
            <p class="desctop_v">Строк: &nbsp;</p>
            <brew-select
              style="width: 100px"
              class="select-pagination"
              :clearable="false"
              v-model="perPage"
              :options="page_sizes"
            >
            </brew-select>
          </div>
          <paginate
            style="margin-left: 20px"
            class="flx"
            v-model="current_page"
            :page-count="meta.last_page"
            :page-range="3"
            :margin-pages="2"
            :prev-text="'<'"
            :next-text="'>'"
          >
          </paginate>
        </div>
      </div>
      <div class="bot_table">
        <headline :headers="headers" :selected-all.sync="isSelectAll" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"></headline>
        <div class="row_list">
          <div v-for="order in orders"
               :key="'order-' + order.id"
               :class="[{ checked: selected && selected.indexOf(order.id) !== -1 }, getOrderClassByStatus(order.status)]"
               class="row flx"
               :rel="order.id">
            <label class="custom-checkbox">
              <input type="checkbox" v-model="selected"  :value="order.id">
              <span></span>
            </label>
            <div class="order_info flx">
              <span class="number_order" @click="routeTo(order.id)">{{ order.id }}</span>
              <span>{{ order.created_at | onlyDate }}</span>
            </div>
            <div class="declaration_info">
              <span class="decl_number" v-if="order.ttn"><b>{{ order.ttn | asTTN }}</b></span>
            </div>
            <div class="delivery_info">
              <span v-if="order.client_data">{{ order.client.last_name }} {{ order.client.first_name | firstLetter }} {{ order.client.middle_name | firstLetter }}</span>
              <span v-if="order.client_data">{{ order.client_data.phone | removeCodeCountry }}</span>
              <span v-if="order.delivery_method_id === 1 && order.client_data && order.client_data.city&&order.client_data.branch">
                  <b v-if="order.client_data.city.name">{{  order.client_data.is_ua ? order.client_data.city.name_ua : order.client_data.city.name  }}</b>
                  <span v-if="order.client_data.branch.number"> (склад {{ order.client_data.branch.number }})</span>
                  <span v-else-if="order.client_data.branch.name"> ({{ order.client_data.is_ua ? order.client_data.branch.name_ua : order.client_data.branch.name }})</span>
              </span>
              <span v-if="order.delivery_method_id === 2 && order.client_data && order.client_data.city">
                  <b v-if="order.client_data.city.name">{{  order.client_data.is_ua ? order.client_data.city.name_ua : order.client_data.city.name  }}</b>
                  <span v-if="order.client_data.address && order.client_data.address.street">
                    {{ order.client_data.address.street ? order.client_data.address.street.name : '' }} {{ order.client_data.address.house ?  order.client_data.address.house : 'н-д'}} {{ order.client_data.address.appt}}
                  </span>
              </span>
            </div>
            <div class="status_info">
              <span class="status" :class="getOrderStatusColor(order.status)">{{ order.status_name }}</span>
            </div>
            <div class="payment_info flx">
              <span class="pay_count" v-if="order.total_final !== order.total_discount">{{ order.total_discount | asPriceNoCurrency }}/{{ order.total_final | asPrice }}</span>
              <span class="pay_count" v-else>{{ order.total_drop | asPrice }}</span>
              <span class="grey">{{ order.pay_type_name }}</span>
            </div>
            <div class="delivery_status_info">
              <span class="status" :class="getOrderDeliveryColor(order.delivery_status)">{{ order.delivery_status_name }}</span>
            </div>
            <div class="items_info flx">
              <span v-for="(product, index ) in order.products" :key="'order-product-'+product.product_color_size_id">
                    <span v-if="index <=1">
                      <span v-if="index>0"> + </span>
                      {{ product.size }} · {{ (product.short_name || product.name) }} · {{ product.color }}
                    </span>
                  </span>
              <span v-if="order.products.length > 2">...</span>
              <svg @click="showProuctsInOrder(order)" class="open_pop" data-id="list_product" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.24443 3.93334C3.86397 3.93334 3.55554 4.24176 3.55554 4.62222C3.55554 5.00269 3.86397 5.31111 4.24443 5.31111H9.75554C10.136 5.31111 10.4444 5.00269 10.4444 4.62222C10.4444 4.24176 10.136 3.93334 9.75554 3.93334H4.24443Z" fill="#ADB8C6"/>
                <path d="M4.24443 6.68889C3.86397 6.68889 3.55554 6.99732 3.55554 7.37778C3.55554 7.75824 3.86397 8.06667 4.24443 8.06667H7.68888C8.06934 8.06667 8.37777 7.75824 8.37777 7.37778C8.37777 6.99732 8.06934 6.68889 7.68888 6.68889H4.24443Z" fill="#ADB8C6"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.799988 1.86667C0.799988 1.10682 1.41792 0.488892 2.17777 0.488892H11.8222C12.5821 0.488892 13.2 1.10682 13.2 1.86667V10.1333C13.2 10.8932 12.5821 11.5111 11.8222 11.5111H2.17777C1.41792 11.5111 0.799988 10.8932 0.799988 10.1333V1.86667ZM10.8669 10.1333H3.13306H2.17777V9.17804V2.82196V1.86667H3.13306H10.8669H11.8222V2.82196V9.17805V10.1333H10.8669Z" fill="#ADB8C6"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <products-in-order-modal v-if="current_order" :order="current_order" :show.sync="showProducts"></products-in-order-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import OrderStatusButtons from '../../../components/FilterOrders/OrderStatusButtons'
import OrdersFilter from '../../../components/FilterOrders/OrdersFilter'
import Headline from '../../../helpers/components/Headline'
import ProductsInOrderModal from '../../../components/Product/ProductsInOrderModal'
import { strings } from '../../../helpers/strings'
import { colors } from '../../../mixins/colors'
import { dates } from '../../../mixins/dates'
import { prices } from '../../../mixins/prices'
import router from '../../../router'
export default {
  name: 'OrdersPage',
  metaInfo () {
    return {
      title: 'Заказы - Личный кабинет'
    }
  },
  pageClass: 'cabinet-page orders-page',
  mixins: [colors, dates, strings, prices],
  data () {
    return {
      page_sizes: [10, 20, 30, 50],
      timer: null,
      headers: [
        {
          title: '',
          check_all: true,
          class: 'custom-checkbox'
        },
        {
          title: 'ЗАКАЗ',
          title_class: 'title',
          sortable: true,
          class: 'order_info',
          value: 'id'
        },
        {
          title: 'Декларация',
          class: 'declaration_info'
        },
        {
          title: 'Клиент・Доставка',
          class: 'delivery_info'
        },
        {
          title: 'СТАТУС',
          class: 'status_info'
        },
        {
          title: 'Оплата',
          sortable: true,
          class: 'payment_info',
          value: 'total_final'
        },
        {
          title: 'Состояние доставки',
          class: 'delivery_status_info'
        },
        {
          title: 'Товары',
          class: 'items_info'
        }
      ]
    }
  },
  components: {
    OrderStatusButtons,
    OrdersFilter,
    Headline,
    ProductsInOrderModal
  },
  computed: {
    ...mapState('orders', [
      'orders',
      'counters',
      'show_filters',
      'status',
      'order_id',
      'sender_id',
      'pay_type',
      'delivery_status',
      'client_last_name',
      'client_phone',
      'city_id',
      'category_id',
      'change_from',
      'change_to',
      'change_id',
      'per_page',
      'page',
      'meta',
      'order_by',
      'order_direction',
      'current_order',
      'show_products_modal'
    ]),
    ...mapGetters('orders', ['getFilters', 'count_order_filters']),
    ...mapState('orders', ['selected_orders', 'search']),
    ...mapState('config', ['config']),
    ...mapState('sender', ['senders']),
    order_config () {
      return this.config?.order || { pay_types: [] }
    },
    showProducts: {
      get () {
        return this.show_products_modal
      },
      set (val) {
        this.setShowProductsModal(val)
        if (!val) {
          this.setCurrentOrder(null)
        }
      }
    },
    order_status: {
      get () {
        return this.status
      },
      set (val) {
        this.setStatus(val)
      }
    },
    orderId: {
      get () {
        return this.order_id
      },
      set (val) {
        this.setOrderId(val)
      }
    },
    senderId: {
      get () {
        return this.sender_id
      },
      set (val) {
        this.setSenderId(val)
      }
    },
    payType: {
      get () {
        return this.pay_type
      },
      set (val) {
        this.setPayType(val)
      }
    },
    deliveryStatusId: {
      get () {
        return this.delivery_status
      },
      set (val) {
        this.setDeliveryStatus(val)
      }
    },
    clientLastName: {
      get () {
        return this.client_last_name
      },
      set (val) {
        this.setClientLastName(val)
      }
    },
    clientPhone: {
      get () {
        return this.client_phone
      },
      set (val) {
        this.setClientPhone(val)
      }
    },
    cityId: {
      get () {
        return this.city_id
      },
      set (val) {
        this.setCityId(val)
      }
    },
    categoryId: {
      get () {
        return this.category_id
      },
      set (val) {
        this.setCategoryId(val)
      }
    },
    changeFrom: {
      get () {
        return this.change_from
      },
      set (val) {
        this.setChangeFrom(val)
      }
    },
    changeTo: {
      get () {
        return this.change_to
      },
      set (val) {
        this.setChangeTo(val)
      }
    },
    changeId: {
      get () {
        return this.change_id
      },
      set (val) {
        this.setChangeId(val)
      }
    },
    selected: {
      get () {
        return this.selected_orders
      },
      set (val) {
        this.setSelectedOrders(val)
      }
    },
    search_order: {
      get () {
        return this.search
      },
      set (value) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.setPage(1)
          this.setSearch(value)
          this.getOrders()
        }, 1000)
      }
    },
    perPage: {
      get () {
        return this.per_page
      },
      set (val) {
        this.setPerPage(val)
        this.$nextTick(() => {
          this.setPage(1)
          this.getOrders()
        })
      }
    },
    current_page: {
      get () {
        return this.page
      },
      set (value) {
        this.setPage(value)
        this.getOrders()
      }
    },
    isSelectAll: {
      get () {
        if (this.orders.length === 0) {
          return false
        }
        return this.selected && this.selected.length === this.orders.length && this.selected.length > 0
      },
      set (value) {
        if (value) {
          this.selected = this.orders.map(item => item.id)
        } else {
          this.selected = []
        }
      }
    },
    sortBy: {
      get () {
        return this.order_by
      },
      set (val) {
        this.setOrderBy(val)
      }
    },
    sortDesc: {
      get () {
        return this.order_direction
      },
      set (val) {
        this.setOrderDirection(val)
      }
    }
  },
  methods: {
    ...mapActions('orders', ['getOrders', 'toggleShowFilters', 'getCounters']),
    ...mapMutations('orders', [
      'clearFilters',
      'setSearch',
      'setStatus',
      'setOrderId',
      'setSenderId',
      'setPayType',
      'setClientLastName',
      'setDeliveryStatus',
      'setClientPhone',
      'setCityId',
      'setCategoryId',
      'setChangeFrom',
      'setChangeTo',
      'setChangeId',
      'setSelectedOrders',
      'setPage',
      'setPerPage',
      'setOrderBy',
      'setOrderDirection',
      'setCurrentOrder',
      'setShowProductsModal'
    ]),
    ...mapActions('sender', ['getSenders']),
    showProuctsInOrder (order) {
      this.setCurrentOrder(order)
      this.setShowProductsModal(true)
    },
    prepare () {
      this.getCounters()
      this.getSenders()
      this.getOrders()
    },
    routeTo (id) {
      router.push({ name: 'EditOrder', params: { id: id } })
    }
  },
  watch: {
    sortBy () {
      this.getOrders()
    },
    sortDesc () {
      this.getOrders()
    }
  },
  created () {
    this.prepare()
  }
}
</script>

<style scoped>

</style>
