<template>
  <div>
    <div class="profile_info container">
      <profile-info></profile-info>
    </div>
    <section class="dinamics_orders">
      <div class="container">
        <div class="top flx">
          <h2>Динамика заказов</h2>
          <div class="right_box flx">
            <div class="dinamics_period_fix">
              <span @click="setPeriod('week')" class="item" :class="{'active': period === 'week'}">Неделя</span>
              <span @click="setPeriod('month')" class="item" :class="{'active': period === 'month'}">Месяц</span>
              <span @click="setPeriod('year')" class="item" :class="{'active': period === 'year'}">Год</span>
              <span @click="setPeriod('all_time')" class="item" :class="{'active': period === 'all_time'}">Всё время</span>
            </div>
            <div class="dinamics_period_from_to flx">
              <span class="grey">Период </span>
              <date-picker v-model="time_range" format="D.M.Y" range></date-picker>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
    </section>
    <section class="top_products_outer">
      <div class="container">
        <div class="top flx">
          <h2>Топ-10 товаров</h2>
          <span class="grey">10 ваших самых популярных товаров</span>
        </div>
        <div class="top_products_inner flx">
          <div class="item_top"
               v-for="(popular, index) in popular_goods" :key="index"
               :style="{ background: popular.color, width: popular.perc + '%' }"
          >
            <span>{{popular.count}} шт</span>
            <div class="menu_drop popular_product">
              <div class="dropdown flx">
                <img v-if="popular.product.image_url" :src="popular.product.image_url" alt="product">
                <img v-else style="object-fit:cover" src="/images/no-img.svg" alt="">
                <span class="name">{{popular.product.name}}</span>
                <div class="popular_prod_info">
                  <div class="item">
                    <span>Артикул:</span>
                    <span class="line"> </span>
                    <p>{{popular.product.vendor_code}}</p>
                  </div>
                  <div class="item">
                    <span>Цвет:</span>
                    <span class="line"> </span>
                    <p>{{popular.product.color}}</p>
                  </div>
                  <div class="item">
                    <span>Цена дроп:</span>
                    <span class="line"> </span>
                    <p>{{ popular.product.retail_price }} грн/шт</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="category_card_outer profile_category">
      <div class="container">
        <h2>Популярные категории</h2>
        <div class="category_card_inner flx">
          <a :href="'/catalog?categories='+item.id" target="_blank" class="card flx" v-for="item in popular_categories" :key="item.id">
            <div v-if="!item.image" class="img_wrap" style="background-image: url(images/tolstovki-i-svitshoty.png);">
              <span class="qty_orders">{{item.count}}<span>заказов</span></span>
            </div>
            <div v-else class="img_wrap" :style="{'background-image': 'url(' + item.image + ')'}">
              <span class="qty_orders">{{item.count}}<span>заказов</span></span>
            </div>
            <span>{{item.name}}</span>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import ProfileInfo from '../../../components/Profile/ProfileInfo'
import DatePicker from 'vue2-datepicker'

import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'ProfilePage',
  metaInfo () {
    return {
      title: 'Профиль - Личный кабинет'
    }
  },
  data: function () {
    return {
      time_range: null,
      period: 'week'
    }
  },
  watch: {
    time_range (value) {
      this.period = 'custom'
      this.getOrderCustomGraph(value)
    }
  },
  computed: {
    ...mapState('statistics', ['series', 'chartOptions', 'popular_goods', 'popular_categories'])
  },
  methods: {
    ...mapMutations('statistics', ['setStatistic']),
    ...mapActions('statistics', ['getOrderGraph', 'getOrderCustomGraph', 'getPopularGoods', 'getPopularCategories']),
    setPeriod (period) {
      this.period = period
      this.getOrderGraph(this.period)
    }
  },
  pageClass: 'cabinet-page profile-page',
  components: {
    ProfileInfo,
    DatePicker,
    apexchart: VueApexCharts
  },
  created () {
    this.setStatistic({ count: [0], date: ['loading'] })
    this.getOrderGraph(this.period)
    this.getPopularGoods()
    this.getPopularCategories()
  },
  beforeDestroy: function () {
    console.log('beforeDestroy')
  }
}
</script>

<style scoped>

</style>
