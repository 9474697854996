<template>
  <div class="table_body reports_table">
    <div class="top_table flx">
      <div class="wrap_search flx">
        <button type="submit"></button>
        <input type="text" placeholder="Поиск...">
        <ul class="search_list_box">
          <li class="item"><a href="#">item</a></li>
          <li class="item"><a href="#">item</a></li>
          <li class="item"><a href="#">item</a></li>
          <li class="item"><a href="#">item</a></li>
          <li class="item"><a href="#">item</a></li>
        </ul>
      </div>
      <div class="pagination flx">
        <div class="flx">
          <p>Строк:  &nbsp;</p>
          <brew-select
            style="width: 100px"
            class="select-pagination"
            :clearable="false"
            v-model="perPage"
            :options="page_sizes"
          >
          </brew-select>
        </div>
        <paginate
          v-model="current_page"
          style="margin-left: 20px"
          class="flx"
          :page-count="payments_pagination.last_page"
          :page-range="3"
          :margin-pages="2"
          :prev-text="'<'"
          :next-text="'>'"
        >
        </paginate>
      </div>
    </div>
    <div class="bot_table">
      <div class="headline_table flx">
        <div class="report_number">
          <p>Отчеты</p>
        </div>
        <div class="date_of_creation">
          <p>Дата создания</p>
        </div>
        <div class="orders_count">
          <p>Заказов</p>
        </div>
        <div class="return_count">
          <p>возвратов</p>
        </div>
        <div class="from_balance">
          <p>С баланса</p>
        </div>
        <div class="calculation_info">
          <p>расчёт</p>
        </div>
        <div class="payout_amount">
          <p>Размер выплаты</p>
        </div>
        <div class="status_report">
          <p>Статус</p>
        </div>
        <div class="date_of_payout">
          <p>ДАТА выплаты</p>
        </div>
        <div class="icon_wrap"></div>
      </div>
      <div class="row_list" v-if="payments.length > 0">
        <div class="row flx" v-for="payment in payments" :key="payment.group">
          <div class="report_number">
            <span class="open_pop" data-id="report-info" @click="selected_group=payment.group">{{ payment.group }}</span>
          </div>
          <div class="date_of_creation">
            <span>{{ payment.created_at }}</span>
          </div>
          <div class="orders_count">
            <span>{{ payment.order_count }}</span>
          </div>
          <div class="return_count">
            <span>{{ payment.returned_count }}</span>
          </div>
          <div class="from_balance">
            <span>{{ payment.balance_count }}</span>
          </div>
          <div class="calculation_info">
            <span>{{ payment.pay_sum }} грн - {{ payment.debit_sum }} грн</span>
          </div>
          <div class="payout_amount">
            <span><b>{{ payment.total_payment }} грн</b></span>
          </div>
          <div class="status_report">
            <span class="status green">Выплачен</span>
          </div>
          <div class="date_of_payout">
            <span>{{ payment.created_at }}</span>
          </div>
          <drop-down-comment v-if="payment.comment" :comment="payment.comment"></drop-down-comment>
        </div>
      </div>
    </div>
    <payment-report-details v-if="selected_group" :group="selected_group"></payment-report-details>
  </div>
</template>

<script>
import helper from '../../../helpers/helper'
import { mapActions, mapMutations, mapState } from 'vuex'
import DropDownComment from './DropDownComment'
import PaymentReportDetails from './PaymentReportDetails'

export default {
  name: 'PaymentReports',
  components: { DropDownComment, PaymentReportDetails },
  props: {
    opened_invoices: Array,
    opened_pagination: Object
  },
  data () {
    return {
      selected_group: null,
      page_sizes: [20, 50, 100]
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('payments', ['payments', 'payments_pagination', 'payments_filter']),
    perPage: {
      get () {
        return this.payments_filter.per_page
      },
      set (val) {
        this.setPaymentsPerPage(val)
        this.$nextTick(() => {
          this.setPaymentsPage(1)
          this.getPayments()
        })
      }
    },
    current_page: {
      get () {
        return this.payments_filter.page
      },
      set (value) {
        this.setPaymentsPage(value)
        this.getPayments()
      }
    }
  },
  methods: {
    ...mapActions('payments', ['getPayments']),
    ...mapActions('invoices', ['getOpenedInvoices', 'getInvoiceUser']),
    ...mapActions('deductions', ['preCreate', 'createDeduction']),
    ...mapMutations('deductions', ['setShowModal']),
    ...mapMutations('payments', ['setPaymentsPage', 'setPaymentsPerPage']),
    getOrderStatusColor (order) {
      return helper.getOrderStatusColor(order.status)
    }
  },
  created () {
    this.getPayments()
    this.$root.$on('close_report', (data) => {
      this.selected_group = null
    })
  }
}
</script>

<style scoped>

</style>
