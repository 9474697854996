<template>
  <div class="filters_box open_box" action="#" data-id="filters_box" :class="{ active: show_filters }">
    <div class="filters_head flx">
      <div class="wrap_title flx">
        <span class="page_title">Фильтры</span>
        <span class="result" v-if="meta.total > 0 && meta.total > meta.per_page">Результат {{ meta.from }} - {{ meta.to }} из {{ meta.total }}</span>
        <span class="result" v-else-if="meta.total > 0">Результат {{ meta.total }}</span>
      </div>
      <div class="flx">
        <div class="reset" @click="clearFilters">Сбросить <span>фильтры</span></div>
        <button class="btn empty" @click="submitFilters">Применить</button>
      </div>
    </div>
    <div class="filters_table flx">
      <div class="column flx">
        <span class="filter_title">Заказ</span>
        <div class="inp_wrap">
          <p>ID заказа</p>
          <input type="text" placeholder="ID Заказа" id="order_id" v-model.number="orderId">
        </div>
        <div class="inp_wrap">
          <p>Отправитель</p>
          <select name="sender" v-model.number="senderId">
            <option :value="null">Все</option>
            <option :value="item.id" v-for="item in senders" :key="item.id">{{ item.last_name }} {{ item.first_name }} {{ item.middle_name }}</option>
          </select>
        </div>
      </div>
      <div class="column flx">
        <span class="filter_title">Состояние заказа</span>
        <div class="inp_wrap">
          <p>Оплата</p>
          <select name="select_paytype" v-model.number="payType">
            <option :value="null">Все</option>
            <option :value="item.id" v-for="item in config.pay_types" :key="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="inp_wrap">
          <p>Статус</p>
          <select name="select_status" v-model.number="statusId">
            <option :value="null">Все</option>
            <option :value="item.id" v-for="item in config.statuses" :key="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="inp_wrap">
          <p>Состояние доставки</p>
          <select name="select_delivery_status" v-model.number="deliveryStatusId">
            <option :value="null">Все</option>
            <option :value="item.id" v-for="item in config.delivery_statuses" :key="item.id">{{ item.name }}</option>
          </select>
        </div>
      </div>
      <div class="column flx">
        <span class="filter_title">Клиент</span>
        <div class="inp_wrap">
          <p>Номер телефона</p>
          <input type="tel" placeholder="Номер телефона" id="number_phone" v-mask="phone_mask" v-model="clientPhone">
        </div>
        <div class="inp_wrap">
          <p>Фамилия клиента</p>
          <input type="text" placeholder="Фамилия" id="surname" v-model="clientLastName">
        </div>
        <div class="inp_wrap">
          <p>Город</p>
          <brew-select
            class="vue-select select"
            @search="fetchCities"
            label="name"
            :options="cities"
            v-model="cityId"
            :reduce="item => item.id"
            id="city"
          ></brew-select>
        </div>
      </div>
      <div class="column flx">
        <span class="filter_title">Товар</span>
        <div class="inp_wrap">
          <p>Категория товара</p>
          <select name="select">
            <option :value="null">Все</option>
            <option :value="item.id" v-for="item in categories" :key="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="inp_wrap">
          <p>Дата</p>
          <div class="wrap">
            <p>Выберите период:</p>
            <div class="wrap_from_to data flx">
              <VueDatePicker style="width: 100%" v-model="changeFrom"  placeholder="От" value-type="YYYY-MM-DD" format="От YYYY-MM-DD" lang="ru" />
              <VueDatePicker style="margin-top: 20px;width: 100%" v-model="changeTo" placeholder="До" value-type="YYYY-MM-DD" format="До YYYY-MM-DD" lang="ru" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrewSelect from '../../helpers/components/BrewSelect'
import 'vue-select/dist/vue-select.css'
import { mask } from 'vue-the-mask'
import repositoryFactory from '../../api/repositoryFactory'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/locale/uk'
const city = repositoryFactory.get('city')
const catalog = repositoryFactory.get('catalog')

// const sender = repositoryFactory.get('sender')
export default {
  name: 'OrdersFilter',
  directives: {
    mask
  },
  components: {
    BrewSelect,
    VueDatePicker: () => import('vue2-datepicker')
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          pay_types: [],
          statuses: [],
          delivery_statuses: []
        }
      }
    },
    senders: {
      type: Array,
      default: () => {}
    },
    show_filters: {
      type: Boolean,
      default: false
    },
    order_id: {
      type: Number || null,
      default: null
    },
    sender_id: {
      type: Number || null,
      default: null
    },
    pay_type: {
      type: Number || null,
      default: null
    },
    status: {
      type: Number || null,
      default: null
    },
    delivery_status: {
      type: Number || null,
      default: null
    },
    client_last_name: {
      type: String || null,
      default: null
    },
    client_phone: {
      type: String || null,
      default: null
    },
    city_id: {
      type: Number || null,
      default: null
    },
    category_id: {
      type: Number || null,
      default: null
    },
    change_from: {
      type: String || null,
      default: null
    },
    change_to: {
      type: String || null,
      default: null
    },
    change_id: {
      type: Number || null,
      default: null
    },
    meta: {
      type: Object,
      default: () => {
        return {
          total: 0,
          from: 1,
          to: 100,
          per_page: 100
        }
      }
    }
  },
  data () {
    return {
      phone_mask: '+38(0##) ###-##-##',
      date_mask: '##-##-20##',
      cities: [],
      categories: []
    }
  },
  computed: {
    orderId: {
      get () {
        return this.order_id
      },
      set (val) {
        this.$emit('update:order_id', val)
      }
    },
    senderId: {
      get () {
        return this.sender_id
      },
      set (val) {
        this.$emit('update:sender_id', val)
      }
    },
    payType: {
      get () {
        return this.pay_type
      },
      set (val) {
        this.$emit('update:pay_type', val)
      }
    },
    statusId: {
      get () {
        return this.status
      },
      set (val) {
        this.$emit('update:status', val)
      }
    },
    deliveryStatusId: {
      get () {
        return this.delivery_status
      },
      set (val) {
        this.$emit('update:delivery_status', val)
      }
    },
    clientPhone: {
      get () {
        return this.client_phone
      },
      set (val) {
        this.$emit('update:client_phone', val)
      }
    },
    clientLastName: {
      get () {
        return this.client_last_name
      },
      set (val) {
        if (val === '') {
          val = null
        }
        this.$emit('update:client_last_name', val)
      }
    },
    cityId: {
      get () {
        return this.city_id
      },
      set (val) {
        this.$emit('update:city_id', val)
      }
    },
    categoryId: {
      get () {
        return this.category_id
      },
      set (val) {
        this.$emit('update:category_id', val)
      }
    },
    changeFrom: {
      get () {
        return this.change_from
      },
      set (val) {
        this.$emit('update:change_from', val)
      }
    },
    changeTo: {
      get () {
        return this.change_to
      },
      set (val) {
        this.$emit('update:change_to', val)
      }
    },
    changeId: {
      get () {
        return this.change_id
      },
      set (val) {
        this.$emit('update:change_id', val)
      }
    }
  },
  methods: {
    submitFilters () {
      this.$emit('update')
    },
    clearFilters () {
      this.$emit('clear')
    },
    async fetchCities (search) {
      if (search.length >= 3) {
        const { data } = await city.get()
        if (data) {
          this.cities = data.data
        }
      }
    },
    async getCategories () {
      const { data } = await catalog.get({ only_root: 0 })
      if (data) {
        this.categories = data.data
      }
    }
  },
  created () {
    this.getCategories()
  }
}
</script>

<style scoped lang="scss">
.vue-select {
  .vs__selected {
    &:hover {
      z-index: 1000;
    }
  }
  .vs--disabled {
    input.vs__search {
      display: none;
    }
  }
}
</style>
